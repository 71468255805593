import React, { Component } from 'react';

class Preface extends Component {
	constructor () {
        super();
        this.state = {
            menu: [],
            hover: '',
            animations: [],
            animationsBackup: [],
            lengths: [],
            selected: '',
            step: [0,0,0,0,0],
        }
        this.reportUsage = this.reportUsage.bind(this);

    }
    
  reportUsage(event) {
    let view =  event.target.getAttribute('name');
    let id = event.target.id;
    let animations = this.state.animationsBackup.map((category, index)=>(

            category.map((step, idx)=>(
                step=category[0]
            ))
    )
    
    
    )
    animations[id] = this.state.animationsBackup[id]; 
    this.setState({
        selected: view,
        animations
    })
    this.props.showFullPage(view);
    const inquiry = {uid: 'LC666', view};
    const address = {
        local: 'http://127.0.0.1:3003/api/lc/usagereport', 
        global: 'https://164.132.113.224/api/lc/usagereport'}
    fetch(address.global, {
        method: 'POST',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    body: JSON.stringify(inquiry)
})

}
componentDidMount(){
        const view = this.props.view ? this.props.view : '';
		const siteContent = this.props.siteContent ? this.props.siteContent : [];
        let animationsBackup = siteContent.map((content, index)=> (content.pictures.animation.map((step, idx) =>
        './lc_ico/' + step
        ))
        )
        let animations = animationsBackup.map((category, index)=>(

            category.map((step, idx)=>(
                step=category[0]
            ))
    ))
        let animationstemp = animationsBackup;
        let menu = animationstemp.map((category, index)=>(category[0]));
        let lengths = animations.map((category, index)=>(
            category.length
            ))
            this.setState({
                animations,
                animationsBackup: animationsBackup,
                menu,
                lengths,
                selected: this.props.view,
            })	
        setTimeout(this.interval_1 = setInterval(() => {
            const hover = 0;
            let menu = this.state.menu;
            let step = this.state.step;
            //console.log(hover + ' - ' + step[hover]);
            menu[hover] = this.state.animations[hover][step[hover]];
            if(step[hover] < this.state.lengths[hover]-1)
                step[hover] = step[hover]+1;
            else
                step[hover] = 0;
                clearInterval(this.interval);
            this.setState({ 
               menu, 
               step
      
               })
          }, 500), 150)
          setTimeout(this.interval_2 = setInterval(() => {
            const hover = 1;
            let menu = this.state.menu;
            let step = this.state.step;
            //console.log(hover + ' - ' + step[hover]);
            menu[hover] = this.state.animations[hover][step[hover]];
            if(step[hover] < this.state.lengths[hover]-1)
                step[hover] = step[hover]+1;
            else
                step[hover] = 0;
                clearInterval(this.interval);
            this.setState({ 
               menu, 
               step
      
               })
          }, 450), 250)
          this.interval_3 = setInterval(() => {
            const hover = 2;
            let menu = this.state.menu;
            let step = this.state.step;
            //console.log(hover + ' - ' + step[hover]);
            menu[hover] = this.state.animations[hover][step[hover]];
            if(step[hover] < this.state.lengths[hover]-1)
                step[hover] = step[hover]+1;
            else
                step[hover] = 0;
                clearInterval(this.interval);
            this.setState({ 
               menu, 
               step
      
               })
          }, 470)
          this.interval_4 = setInterval(() => {
            const hover = 3;
            let menu = this.state.menu;
            let step = this.state.step;
            //console.log(hover + ' - ' + step[hover]);
            menu[hover] = this.state.animations[hover][step[hover]];
            if(step[hover] < this.state.lengths[hover]-1)
                step[hover] = step[hover]+1;
            else
                step[hover] = 0;
                clearInterval(this.interval);
            this.setState({ 
               menu, 
               step
      
               })
          }, 1000)
          this.interval_5 = setInterval(() => {
            const hover = 4;
            let menu = this.state.menu;
            let step = this.state.step;
            //console.log(hover + ' - ' + step[hover]);
            menu[hover] = this.state.animations[hover][step[hover]];
            if(step[hover] < this.state.lengths[hover]-1)
                step[hover] = step[hover]+1;
            else
                step[hover] = 0;
                clearInterval(this.interval);
            this.setState({ 
               menu, 
               step
      
               })
          }, 500)
    }



    
	render() {
		const view = this.props.view ? this.props.view : '';
		const siteContent = this.props.siteContent ? this.props.siteContent : [];
		//const content = siteContent.filter(page => page.link === view);
		return (
		<div className='preface'>
			{siteContent.map((content, index)=> (
					<div key={index} id={content.link} name={content.link} onClick={this.reportUsage}>		
						{//<h1>{content.title}</h1>
						}
						<img style={{transform: this.state.selected===content.link ? 'scale(1.3) ': 'scale(0.6)'}} name={content.link} id={index} src={this.state.menu[index]} alt={content.title}/>	
						<p name={content.link} onClick={this.reportUsage}>{content.title}</p>
					</div>
			))			
			}

		</div>
		) 
	}
}

export default Preface;