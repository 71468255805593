import React, { Component } from "react";
import './App.css';
import { Navbar, Nav} from 'react-bootstrap';
import LangBar from "./LangBar";

class NavigationBar extends Component {
    constructor () {
        super();
        this.state = {
        }
        this.reportUsage = this.reportUsage.bind(this);
    }
    
  reportUsage(event) {
    let view = event.target.name;
    this.props.showFullPage(view);
    const inquiry = {uid: 'LC666', view};
    const address = {
        local: 'http://127.0.0.1:3003/api/lc/usagereport', 
        global: 'https://164.132.113.224/api/lc/usagereport'}
    fetch(address.global, {
        method: 'POST',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(inquiry)
    })
    }
    

  render (){
    const basicMenu = [{title: this.props.navigationBar[0], link: 'about'}, {title: this.props.navigationBar[1], link: 'kontakt'}];
    let siteContent = this.props.siteContent ? this.props.siteContent : [];
    siteContent = siteContent.concat(basicMenu);
    return (
        <Navbar id='page-navigation' collapseOnSelect expand="lg">
            <Navbar.Brand href="/"><img src='./lc_logo/lc_logo_mini.svg' alt="Laskowski Creative"/><img className="logotype-nav" src='./lc_logo/lc_type.svg' alt="Laskowski Creative"/></Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse >
                <Nav className="ml-auto">
                    {siteContent.length > 0 && siteContent.map((item, index)=>(
                        <Nav.Link name={item.link} key={index} href={'#'+item.link} onClick={this.reportUsage}>{item.title}</Nav.Link>                    
                    ))}
                </Nav>
            </Navbar.Collapse>
            <LangBar setLanguage={this.props.setLanguage} lang={this.props.lang} />
        </Navbar>
    )}
}

export default NavigationBar;