import React, { Component } from "react";
import HeadCarousel from './HeadCarousel';
import NavigationBar from './NavigationBar';
import MainContent from './MainContent';
import Footer from './Footer';
import FullPage from "./FullPage";
import AboutPage from "./AboutPage";
import AboutUs from "./AboutUs";
import Preface from "./Preface";
import LangBar from "./LangBar";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'popper.js/dist/popper.js';
import $ from 'jquery';
import ContactPanel from "./ContactPanel";
import {chooseLanguage} from './langSettings';
import CookiesPopup from "./CookiePopup";
import Cookies from 'universal-cookie';

class App extends Component {
  constructor () {
    super();
	  this.state = {
      lang: 'no',
      view: 'default',
      contactForm: ['name', 'e-mail', 'phone', 'message'],
      navigationBar: ['About', 'Contact'],
      accept: 'Jeg forstår',
      contactRules: 'Jeg godtar behandlingen av mine personlige opplysninger gitt i dette forespørselsskjemaet av Laskowski Creative for å kontakte deg og svare på det stilte spørsmålet.',
      cookiesPopup: 'På Laskowski Creative nettsider bruker vi og våre partnere informasjonskapsler (cookies) for personlig tilpasning av annonser og måling. Du kan lese mer om dette i vår personvernpolicy og vår informasjonsside om informasjonskapsler.',
      thankYouHeader: ['Takk for melding!', 'Vi kontakter deg snart.'],
      logoAnimation: 0,
      backgroundPositionX: 1600,
      siteContent: [
        {
          link: 'MarketingPage',
          pictures: {
            mini: 'lc_ico/lc_marketing.svg', 
            slider: 'lc_ico/lc_marketing.svg', 
            animation: [
              'lc_marketing_00.svg',
              'lc_marketing_01.svg',
              'lc_marketing_02.svg',
              'lc_marketing_03.svg',
              'lc_marketing_04.svg',
              'lc_marketing_05.svg',
              'lc_marketing_06.svg',
              'lc_marketing_00.svg',
              'lc_marketing_00.svg',
            ]
          },
          title: 'Online Marketing',
          description: [
            "Merkevareidentitet og markedsføring på Internett",
            'Sosiale medier',
          ],
          page: [
            {
              miniature: {
                file: '',
                fill: '',
                x: '',
                y: ''
              },
              title: 'Merkevareidentitet og markedsføring på Internett',
              content: [
                "Vi vil optimalisere og posisjonere nettstedet ditt, nettstedet ditt vil være synlig med de mest populære nøkkelsetningene i din bransje.",
                "Vi vil utføre Google Ads i søkeresultater, Google Shopping-kampanjer, kampanjer i annonsenettverk, remarketing, annonser på YouTube."
            ],
              caption: "Vi vil bygge og konsolidere et profesjonelt image av bedriften din og hjelpe deg med å skape en gjenkjennelig merkevare som vil få anbefalinger.",
            },

            {
              miniature: {
                file: '',
                fill: '',
                x: '',
                y: ''
              },
              title: 'Sosiale medier',
              content: [
                'Vi vil opprette dine profesjonelle sosiale mediekontoer for bedrifter eller optimere de eksisterende. Vi skal lede dem til å bygge en sterk merkevare og tiltrekke nye kunder! ',
                
            ],
              caption: "Vi vil planlegge kommunikasjonsstrategier for sosiale medier og gjennomføre reklamekampanjer for å bygge varige relasjoner med kundene dine."
            },
          ],
          caption: 'Online Marketing',
        },
        {
          link: 'CodePage',
					pictures: {
            mini: 'lc_ico/lc_code.svg', 
            slider: 'lc_ico/lc_code.svg',
            animation: [
              'lc_code_07.svg',
              'lc_code_00.svg',
              'lc_code_01.svg',
              'lc_code_02.svg',
              'lc_code_03.svg',
              'lc_code_04.svg',
              'lc_code_05.svg',
              'lc_code_06.svg',
              'lc_code_07.svg',
            ]
          },
					title: 'Kode',
					description: [
            'Design og implementering av nettsider',
            'Nettbutikksider',
            'Online bookingsystemer',
            "Applikasjonsintegrasjon med eksisterende systemer"
        ],
          page: [
            {
              miniature: {
                file: '',
                fill: '',
                x: '',
                y: ''
              },
              title: 'Design og implementering av nettsider',
              content: [
                'Vi er kvalifisert til å utvikle programvare med fullt ansvar for kontroll og kvalitet på alle stadier av programvarens levetid.',
                'Vi tilbyr en sikker og pålitelig serverinfrastruktur for funksjonen til nettstedet og klientens e-post. ',
            ],
              caption: 'Vi vil lage din profesjonelle og moderne nettside, takket være den vil du kunne kommunisere med kundene dine.',
            },
            {
              miniature: {
                file: '',
                fill: '',
                x: '',
                y: ''
              },
              title: 'Nettbutikksider',
              content: [
                "Nettbutikker har blitt det viktigste og mest brukte verktøyet for å nå målgruppen.",
                'Arbeidet til nettbutikken kan støttes av ulike typer salgsplattformer, legge til produkter på prissammenligningsnettsteder eller lage en mobilapplikasjon for smarttelefoner og nettbrett.',
            ],
              caption: 'Vi tilbyr proprietære e-handelsløsninger.',
            },
            {
              miniature: {
                file: '',
                fill: '',
                x: '',
                y: ''
              },
              title: 'Online bookingsystemer',
              content: [
                'Vi tilbyr omfattende tjenester innen applikasjonsutvikling og andre moderne e-business-løsninger.',
            ],
              caption: 'Nøkkelfunksjonalitet til hotellnettsteder, kosmetikk og medisinske tjenester',
            },
            {
              miniature: {
                file: '',
                fill: '',
                x: '',
                y: ''
              },
              title: 'Applikasjonsintegrasjon med eksisterende systemer',
              content:  [
                'Applikasjonsintegrasjon sikrer utveksling av informasjon mellom ulike applikasjoner.',
                "Målet er å lage et enhetlig system for å støtte organisasjonen som skal ta seg av alt fra kundeservice til regnskap, økonomi og lagertjenester for selskapet."
            ],
              caption: "Akkurat som byggherrer kombinerer ulike materialer til ekstraordinære bygninger, er vi i stand til å kombinere ulike IT-løsninger til en nyttig helhet."
            },
          ],
          caption: 'Kode',
				},
        {
          link: 'GraphicDesign',
          pictures: {
            mini: 'lc_ico/lc_graphic.svg',
            slider: 'lc_ico/lc_graphic.svg',
            animation: [
              'lc_graphic_00.svg',
              'lc_graphic_01.svg',
              'lc_graphic_02.svg',
              'lc_graphic_03.svg',
              'lc_graphic_04.svg',
              'lc_graphic_05.svg',
              'lc_graphic_06.svg',
              'lc_graphic_07.svg',
              'lc_graphic_08.svg',
              'lc_graphic_09.svg',
              'lc_graphic_10.svg',
              'lc_graphic_11.svg',
              'lc_graphic_11.svg',
            ]
          },
          title: 'Grafisk Design',
          description:  [
            'Logo',
            'Visittkort',
            'Reklame på biler',
            'Reklameprodukter',
            'Etiketter',
            ],
            page: [
              {
                miniature: {
                  file: '',
                  fill: '',
                  x: '',
                  y: ''
                },
                title: 'Logo',
                content: ['Logoer omgir oss og er en integrert del av livene våre. Det kundene ser er en viktig del av selskapets image. '],
                caption: 'Logoen er førsteinntrykket av din bedrift for kunden',
              },
              {
                miniature: {
                  file: '',
                  fill: '',
                  x: '',
                  y: ''
                },
                title: 'Visittkort',
                content: ['Det er viktig at visittkort er riktig utformet og trykt på papir av høy kvalitet.'],
                caption: 'Det er et veldig kraftig reklameverktøy'
              },
              {
                miniature: {
                  file: '',
                  fill: '',
                  x: '',
                  y: ''
                },
                title: 'Reklame på biler',
                content: ['Vi driver med profesjonell bilreklame. Takket være våre annonser vil du være mer synlig for kundene dine. '],
                caption: 'En firmabil er en kjørereklame'
              },
              {
                miniature: {
                  file: '',
                  fill: '',
                  x: '',
                  y: ''
                },
                title: 'Reklameprodukter',
                content: ['Det lar deg nå et bredt publikum på kort tid. Riktig utvalg av papir, teknikker og grafikk mens du fortsatt er på designstadiet kan gjøre dette populære reklameverktøyet til et ekte mesterverk! '],
                caption: 'Flyeren er et tidløst markedsføringsverktøy'
              },
              {
                miniature: {
                  file: '',
                  fill: '',
                  x: '',
                  y: ''
                },
                title: 'Etiketter',
                content: ['Vi vil lage etiketter av høyeste kvalitet som vil skille produktet ditt. Våre etiketter har akkurat de egenskapene som kunden trenger, selv om det er spesialetiketter med spesielt høye krav. '],
                caption: 'Trenger du et etikettdesign for produktet ditt?'
              },
            ],
          caption: 'Grafisk Design',
        },
        {
          link: 'FotoPage',
          pictures: {
            mini: 'lc_ico/lc_dron.svg', 
            slider: 'lc_ico/lc_dron.svg',  
            animation: [
              'lc_foto_00.svg',
              'lc_foto_01.svg',
              'lc_foto_01.svg',
              'lc_foto_01.svg',
              'lc_foto_01.svg',
              'lc_foto_01.svg',
              'lc_foto_01.svg',
            ],
          },
          title: 'Foto & Video',
          description: [
            'Fotografia i rejestracja wideo',
            'Ujęcia dronem',
            'Spoty reklamowe'       
          ],
          page: [
            {
              miniature: {
                file: '',
                fill: '',
                x: '',
                y: ''
              },
              title: 'Fotografi og videoopptak',
              content: [
                'Vi spesialiserer oss på:',
                '- bilder av arkitektur',
                '- interiør', '- reportasje',
                '- og for e-handel.'
            ],
              caption: 'Vi vil ta profesjonelle reklamebilder.'
            },
            {
              miniature: {
                file: '',
                fill: '',
                x: '',
                y: ''
              },
              title: 'Dronefotografering',
              content:  [
                'Kampanjebilder fra en drone for selskaper, eiendomsmeglere og utviklere er vår spesialitet.',
                "Å inspisere taket med en drone og andre vanskelig tilgjengelige steder er et flott verktøy som hjelper med eiendomsgodkjenning.",

            ],
              caption: "Vi vil lage vakre luftbilder i den beste 4K-kvaliteten"
            },
            {
              miniature: {
                file: '',
                fill: '',
                x: '',
                y: ''
              },
              title: 'Reklamefilmer',
              content:  [
                'Videomarkedsføring gir anerkjennelse og et konkurransefortrinn.',
                'En godt utført bildefilm vil gjøre det lettere for kundene å forstå merkevaren bedre, og selskapet vil øke salgsnivået. ',
                "Filmen lar deg fremheve de viktigste fordelene.",

            ],
              caption: "Video er den mest effektive formen for annonsering!"
            },
          ],
          caption: 'Usługi foto i rejestracji wideo',
        },
        {
          link: 'ITSupportPage',
          pictures: {
            mini: 'lc_ico/lc_support.svg', 
            slider: 'lc_ico/lc_support.svg',
            animation: [
              'lc_support_00.svg',
              'lc_support_01.svg',
              'lc_support_02.svg',
              'lc_support_03.svg',
              'lc_support_04.svg',
            ]
          },
          title: 'IT-støtte',
          description: [
            'IT-støtte',
            'Sikkerhetskopiering og datagjenoppretting',
            'Opplæring'
        ],
            page: [
              {
                miniature: {
                  file: '',
                  fill: '',
                  x: '',
                  y: ''
                },
                title: 'IT-støtte',
                content: [
                  'Vi diagnostiserer og reparerer utstyr for å håndtere ethvert problem! Vi tilbyr utprøvd maskinvare og programvare ',
                  'Vi sørger for at IT-systemet ditt holdes i full stand 24 timer i døgnet!',
              ],
                caption: 'Rask fjernkontroll og telefonassistanse'
              },
              {
                miniature: {
                  file: '',
                  fill: '',
                  x: '',
                  y: ''
                },
                title: 'Sikkerhetskopiering og datagjenoppretting',
                content: [
                  'Sikkerhetskopier må være klare for å gjenopprette de originale dataene i tilfelle tap.',
                  'I tilfelle en feil, vil vi iverksette alle tiltak for å gjenopprette dataene dine.',
              ],
                caption: 'Støtte i krisesituasjoner'
              },
              {
                miniature: {
                  file: '',
                  fill: '',
                  x: '',
                  y: ''
                },
                title: 'Opplæring',
                content: [
                  "Programvare og IT-systemer er arbeidsverktøy, vi vet hvordan de fungerer og vi deler gjerne kunnskapen vår under treningsøktene."
              ],
                caption: 'Kunnskap er makt'
              },
            ],
          caption: 'IT-støtte',
        },
      ],
    }
    this.showFullPage = this.showFullPage.bind(this);
    this.setLanguage = this.setLanguage.bind(this);
  }

  handshake() {
    const inquiry = {uid: 'LC333'};
    const address = {
        local: 'http://127.0.0.1:3003/api/lc-kingdom/givemelog', 
        global: 'https://laskowskicreative.no/api/lc-kingdom/givemelog'}
    fetch(address.global, {
        method: 'GET',
    })
    .then((res) => res.json())      
    .then((json) => this.setState(
      {
        activatePage: true,
        log: json.dailyReport
      }
      ))  
    }

 


  componentDidMount() {
    const cookies = new Cookies();
    const cookiesAccepted = cookies.get('cookies-accepted') ? cookies.get('cookies-accepted') : false;
    const lang = cookies.get('lang') ? cookies.get('lang') : 'no';
    let langPack = chooseLanguage(lang);
    let siteContent = [...this.state.siteContent];
    let categories = ['marketing', 'code', 'graphic', 'foto', 'supportIT']
    siteContent[0]['description'] = langPack.description['marketing'];
    siteContent[1]['description'] = langPack.description['code'];
    siteContent[2]['description'] = langPack.description['graphic'];
    siteContent[3]['description'] = langPack.description['foto'];
    siteContent[4]['description'] = langPack.description['supportIT'];
    siteContent.map((content, index)=>
    {
      content.title = langPack.title[index];
       content.page.map((page, idx) => {
        page.content = langPack.content[categories[index]][idx]
        page.title = langPack.description[categories[index]][idx]
        page.caption = langPack.caption[categories[index]][idx]
        return page;
      })
    })

    this.setState({
      cookiesAccepted,
      siteContent,
      lang,
      about: langPack.about,
      accept: langPack.accept,
      cookiesPopup: langPack.cookies,
      navigationBar: langPack.navigation,
      contactForm: langPack.kontakt,
      contactRules: langPack.kontaktRules
    });
    let logoAnimation = this.state.logoAnimation;
    let backgroundPositionX = this.state.backgroundPositionX;
    let direction = 'forward';
    this.interval = setInterval(() => {
      if (logoAnimation <= 25 && direction==='forward')
        this.setState({ 
          logoAnimation: logoAnimation++,

         })
      else
        direction='backward';
      if (logoAnimation > 0 && direction==='backward')
        this.setState({ 
          logoAnimation: logoAnimation--, })
      else
        direction='forward';
    }, 160);

    this.interval = setInterval(() => {
      this.setState({ 
          backgroundPositionX: backgroundPositionX++,

         })
    }, 300);  
    this.handshake();
    $(document).scroll(function () {
      var y = $(this).scrollTop();
      y = y+450;


      $('#page-navigation').each(function () {
        var t = 1500;
        if (y > t) {
          $('nav').css("visibility", "visible");
          $(this).css("display", "flex");
          $(this).css("visibility", "visible");
          $(this).fadeIn();
        } else {
            $(this).fadeOut();
        }
      });
      $('.picture').each(function () {
        var t = $(this).parent().offset().top;
        if (y > t) {
            $(this).slideDown();
        } else {
            $(this).slideUp();
        }
      });
      
    });
  }
  showFullPage (link) {
    this.setState({
      view: link
    })
  }
  setLanguage(lang) {
    let langPack = chooseLanguage(lang);
      const cookies = new Cookies();
      let expires = new Date();
      expires.setTime(expires.getTime() + (365 * 24 * 60 * 60 * 1000));
      cookies.set('lang', lang, expires.toUTCString(), {path: '/'});
    let siteContent = [...this.state.siteContent];
    let categories = ['marketing', 'code', 'graphic', 'foto', 'supportIT']
    siteContent[0]['description'] = langPack.description['marketing'];
    siteContent[1]['description'] = langPack.description['code'];
    siteContent[2]['description'] = langPack.description['graphic'];
    siteContent[3]['description'] = langPack.description['foto'];
    siteContent[4]['description'] = langPack.description['supportIT'];
    siteContent.map((content, index)=>
    {
      content.title = langPack.title[index];
       content.page.map((page, idx) => {
        page.content = langPack.content[categories[index]][idx]
        page.title = langPack.description[categories[index]][idx]
        
        page.caption = langPack.caption[categories[index]][idx]
        return page;
      })
    })

    this.setState({
      siteContent,
      lang,
      about: langPack.about,
      accept: langPack.accept,
      cookiesPopup: langPack.cookies,
      navigationBar: langPack.navigation,
      contactForm: langPack.kontakt,
      contactRules: langPack.kontaktRules
    });
  }
  

  render (){
    let log = this.state.log ? this.state.log : '';
    let logArray = log.split('\u001b');
    logArray.reverse();
    let headerColor = [
      'hsla(0, 0%, 100%, .6)',
      'hsla(0, 0%, 100%, .7)',
      'hsla(166, 25%, 85%, 1)',
      'radial-gradient(circle, rgba(9,87,121,1) 0%, rgba(141,159,171,1) 35%, rgba(3,25,39,1) 100%)',
      'radial-gradient(circle, rgba(141,159,171,1) 0%, rgba(9,87,121,1) 35%, rgba(3,25,39,1) 100%)',
      'linear-gradient(180deg, rgba(9,87,121,1) 0%, rgba(141,159,171,1) 35%, rgba(3,25,39,1) 100%)',
      'linear-gradient(360deg, rgba(9,87,121,1) 0%, rgba(141,159,171,1) 35%, rgba(3,25,39,1) 100%)',
      'linear-gradient(60deg,  rgba(141,159,171,1) 0%, rgba(9,87,121,1) 35%, rgba(3,25,39,1) 100%)',
      'linear-gradient(60deg, rgba(3,25,39,1) 0%,rgba(9,87,121,1) 35%, rgba(141,159,171,1) 100%)',
      'url(/lc_background_09.png)'];
    let logoAnimation = [
      'perspective( 800px ) rotateY( -27deg )',
      'perspective( 800px ) rotateY( -27deg )', 
      'perspective( 800px ) rotateY( -26deg )',
      'perspective( 800px ) rotateY( -25deg )',
      'perspective( 800px ) rotateY( -24deg )', 
      'perspective( 800px ) rotateY( -22deg )',
      'perspective( 800px ) rotateY( -20deg )',
      'perspective( 800px ) rotateY( -17deg )', 
      'perspective( 800px ) rotateY( -15deg )',
      'perspective( 800px ) rotateY( -12deg )', 
      'perspective( 800px ) rotateY( -9deg )',
      'perspective( 800px ) rotateY( -6deg )',
      'perspective( 800px ) rotateY( -3deg )', 
      'perspective( 800px ) rotateY( 0deg )',  
      'perspective( 800px ) rotateY( 3deg )',
      'perspective( 800px ) rotateY( 6deg )', 
      'perspective( 800px ) rotateY( 9deg )',
      'perspective( 800px ) rotateY( 12deg )', 
      'perspective( 800px ) rotateY( 15deg )',
      'perspective( 800px ) rotateY( 17deg )', 
      'perspective( 800px ) rotateY( 20deg )', 
      'perspective( 800px ) rotateY( 22deg )', 
      'perspective( 800px ) rotateY( 24deg )',
      'perspective( 800px ) rotateY( 25deg )', 
      'perspective( 800px ) rotateY( 26deg )', 
      'perspective( 800px ) rotateY( 27deg )', 
      'perspective( 800px ) rotateY( 27deg )'
    ]
    return (
      <div className="App" style={{color: '#FFF', textAlign: 'left', marginLeft: '2vw', display: this.state.activatePage === false ? 'none' : 'inherit'}}>
        <div className="log">
            {logArray.map((line, index) => (
              line!=='[0;39m ' ? <p style={{color: line.slice(0, 6) === '[0;39m' ? '#00FF00': line.slice(0, 6) === '[0;33m' ? '#FFFF00': line.slice(0, 6) === '[0;31m' ? '#FF0000': '#FFF' }} key={index}>{line.slice(6, line.length)}</p> : ''
            ))}
        </div>
      </div>
    );
  }
}

export default App;
